.gallery-wrapper {
    padding: 30px;
}


.myrow {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    justify-content: center;
}

    .myrow img {
        margin: 4px;
        vertical-align: middle;
        width: 32%;
        cursor: pointer;
        opacity: .9;
        transition: opacity .3s ease-in-out;
        object-fit: cover;
    }

        .myrow img:hover {
            opacity: 1;
        }

@media screen and (max-width: 800px) {
    .myrow img {
        width: 47%;
    }
}

@media screen and (max-width: 600px) {

    .gallery-wrapper{
      padding: 20px 0px;
    }
    .myrow img {
        width: 47%;
    }


}



.slider-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in;
    overflow-y: auto;
    padding: 20px;
}


    .slider-overlay.show {
        opacity: 1;
        pointer-events: all;
        display: flex;
    }


.slider-light--content {
    max-width: 991px;
    height: auto;
    margin: auto;
}

.light-close {
    display: inline-block;
    margin-right: auto;
    color: #ffffff;
    position: inherit;
    right: 50px;
    top: 50px;
    cursor: pointer;
    z-index: 999;
}

    .light-close i {
        font-size: 1.5rem;
    }


@media screen and (max-width: 991px) {
    .slider-light--content {
        max-width: 80%;
    }
}