.product-detail-container {
    display: flex;
    margin-top: 15px!important;
    margin-bottom: 100px!important;
    padding: 20px 0px;
    background: #ffffff;
}

.documnet-container{
    width: 70%;
}

.product-info{
    width: 30%;
    padding: 10px 20px;
}

    .product-info h1 {
        font-size: 2.2rem;
        font-weight: 700;
        margin: 0px 0px;
        line-height: 1.4em;
        color: #243559;
        margin-bottom: 15px;

    }

    .product-info p{
        margin-bottom: 30px;
    }
    .product-info--price {
        position: relative;
        color: #7fc241;
        font-size: 28px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 15px;
        text-decoration: inherit;
    }

.product-info--stock{
    margin: 20px 0px;
}
.Product-share h5 {
    color: #243559;
    font-size: 16px;
    font-weight: 500;
}


.product-info--stock {
    color: #243559;
    font-size: 16px;
    font-weight: 500;
}


.detail-text {
    color: #8e8e8e;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
}

.smilarproduct{
    display: flex;
    flex-wrap:wrap;
    background: #ffffff;
    padding: 20px 0px;
    margin-bottom: 2rem;
}

.smilarproduct-container h1 {
    font-size: 2rem;
    font-weight: 600;
}

.product-detail-btn a {
    text-align: center !important;
    display: block;
    background: var(--main-color-orange)!important;
}




@media (max-width: 991px) {
    .product-detail-container {
        flex-direction: column;
        margin-top: 20px !important;
    }
    .documnet-container, .product-info {
        width: 90%;
        margin: 15px auto;
    }

    .product-info h1 {
        font-size: 1.5rem;
    }

    .smilarproduct-container h1{
        font-size: 1.5rem;
    }

}
