.pagination-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2rem 0rem;
}


.page-link {
    border-bottom: 2px solid transparent;
}