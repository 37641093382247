@font-face {
    font-family: "Material Icons Outlined";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v54/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format("woff2");
}

.material-icons-outlined {
    font-family: "Material Icons Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}


.contact_wrapper {
    display: flex;
    background: white;
    padding: 40px 25px;
    box-shadow: 0 0px 4px rgb(0 0 0 / 18%);
    position: relative;
    width: 700px;
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper {
        width: 80%;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .contact_wrapper {
        width: 90%;
        flex-direction: column;
        padding-top: 0px;
    }
}

.contact_wrapper .contact_info {
    position: relative;
    width: 260px;
    flex: 0 0 230px;
    background: var(--main-color-orange);
    color: #ffffff;
    margin-left: calc(-230px / 2 - 30px);
    padding: 25px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper .contact_info {
        width: 100%;
        flex: 0 0 100%;
        margin-left: -50px;
        margin-top: -15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .contact_wrapper .contact_info {
        width: 100%;
        flex: 0 0 100%;
        margin-left: 0px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
}

.contact_wrapper .contact_info .title {
    font-size: 24px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 30px;
}

.contact_wrapper .contact_info .icons_wrapp {
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-bottom: 30px;
}

    .contact_wrapper .contact_info .icons_wrapp li {
        display: flex;
        align-items: baseline;
        margin-bottom: 25px;
    }

        .contact_wrapper .contact_info .icons_wrapp li .icon .material-icons-outlined {
            vertical-align: middle;
            font-size: 24px;
            width: 32px;
        }

        .contact_wrapper .contact_info .icons_wrapp li .text {
            vertical-align: middle;
            line-height: 1.4;
            font-size: 14px;
        }

.contact_wrapper .contact_info .soci_wrap {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    position: absolute;
    bottom: 25px;
}

    .contact_wrapper .contact_info .soci_wrap li {
        margin: 0px 5px;
    }

        .contact_wrapper .contact_info .soci_wrap li a {
            color: #f4f4f4;
            text-decoration: none;
            transition: all 0.3s ease;
        }

            .contact_wrapper .contact_info .soci_wrap li a:hover {
                color: #fff38f;
            }

            .contact_wrapper .contact_info .soci_wrap li a .material-icons-outlined {
                font-size: 32px;
            }

.contact_wrapper .contact_msg {
    width: calc(100% - 230px / 2 - 55px);
    flex: 0 0 calc(100% - 230px / 2 - 55px);
    padding: 25px 30px;
    padding-bottom: 0px;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper .contact_msg {
        width: calc(100% - 25px - 30px);
        flex: 0 0 calc(100% - 25px - 30px);
    }
}

@media (max-width: 767px) {
    .contact_wrapper .contact_msg {
        width: calc(100%);
        flex: 0 0 calc(100%);
        padding: 25px 0px;
    }
}

.contact_wrapper .contact_msg .title {
    font-size: 24px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 0 15px;
    color: #095a54;
}

.contact_wrapper .contact_msg .form_fild {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

    .contact_wrapper .contact_msg .form_fild .input_group {
        position: relative;
        margin-bottom: 25px;
        padding: 0 15px;
    }

        .contact_wrapper .contact_msg .form_fild .input_group.w_50 {
            width: calc(50% - 30px);
            flex: 0 0 calc(50% - 30px);
        }

@media (max-width: 767px) {
    .contact_wrapper .contact_msg .form_fild .input_group.w_50 {
        width: calc(100% - 30px);
        flex: 0 0 calc(100% - 30px);
    }
}

.contact_wrapper .contact_msg .form_fild .input_group.w_100 {
    width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
}

.contact_wrapper .contact_msg .form_fild .input_group .input {
    width: 100%;
    min-height: 30px;
    padding: 5px 0px;
    border: none;
    font-size: 18px;
    color: #444;
    outline: none;
    font-weight: 400;
    border-bottom: solid 2px #e9e9e9;
}

    .contact_wrapper .contact_msg .form_fild .input_group .input.input_textarea {
        min-width: 100%;
        min-height: 75px;
        max-height: 75px;
        max-width: 100%;
    }

    .contact_wrapper .contact_msg .form_fild .input_group .input:hover {
        border-bottom-color: #ddd;
    }

    .contact_wrapper .contact_msg .form_fild .input_group .input:focus {
        border-bottom-color: #aaa;
    }

        .contact_wrapper .contact_msg .form_fild .input_group .input:valid ~ .placeholder, .contact_wrapper .contact_msg .form_fild .input_group .input:focus ~ .placeholder {
            top: -50px;
            font-size: 12px !important;
            color: #095a54;
        }

            .contact_wrapper .contact_msg .form_fild .input_group .input:valid ~ .placeholder.textarea, .contact_wrapper .contact_msg .form_fild .input_group .input:focus ~ .placeholder.textarea {
                top: -12px;
            }

.contact_wrapper .contact_msg .form_fild .input_group .placeholder {
    position: absolute;
    left: 15px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #bbb;
    font-weight: 400;
    transition: all 0.3s ease;
    pointer-events: none;
}

    .contact_wrapper .contact_msg .form_fild .input_group .placeholder.textarea {
        align-items: baseline;
    }

.contact_wrapper .contact_msg .form_fild .input_group .btn {
    padding: 12px;
    border: none;
    border-radius: 0px;
    background-color: var(--main-color-orange);
    color: white;
    min-width: 120px;
    outline: none;
    transition: all 0.3s ease;
}

    .contact_wrapper .contact_msg .form_fild .input_group .btn:hover {
        background-color: #e46112;
    }

    .contact_wrapper .contact_msg .form_fild .input_group .btn:active {
        transform: scale(0.95);
    }


.map-container {
    width: 100%;
}

    .map-container iframe {
        width: 100% !important;
    }
