/* Provide sufficient contrast against white background */
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif);

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


html {
    scroll-behavior: smooth;
}


:root {
    /*  --main-bg-blue: #3962a2;
    --main-color-orange: #f27021;*/
     --main-bg-blue: #3962a2;
    --main-color-orange: #e22a2a;
}


body {
    font-size: 16px;
    word-wrap: break-word !important;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    width: 100%;
}


.mycontainer {
    max-width: 1200px;
    margin: 0 auto;
}


.section-title {
    /*color: var(--main-bg-blue);*/
    color: #333333;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
    position: relative;
}


    .section-title::before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 60px;
        height: 3px;
        background: var(--main-color-orange);
    }


    .section-title.text-left::before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0%!important;
        transform: translate(0%, 0%);
    }

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    border-radius: 10px;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    background-color: #333333;
}

.section-ptb{
    padding: 4rem 0rem;
}
@media screen and (max-width: 991px) {
    .mycontainer {
        padding: 0px 15px;
    }
}