.productcategory-content, .category-products {
    display: flex;
    flex-wrap: wrap;
   
}

.category-menu {
    width: 25%;
    padding: 30px 0px;
    background: #ffffff;
    position: sticky;
    position: -webkit-sticky;
    top: 70px;
}

.category-products {
    width: 75%;
    padding-top: 20px;
}

    .category-products.product-notfound {
        justify-content: center;
        align-items: center;
        font-family: "Playball", cursive;
        background: #ffffff;
        color: var(--main-color-orange);
    }

.category-sidemenu {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    list-style-type: none;
    padding: 0;
    width: 100%;
}

    .category-sidemenu ul > li {
        padding: 4px;
        background: #e8e8e8;
        display: block;
    }

    .category-sidemenu ul li ul li {
        padding: 4px 8px;
       
    }

.category-sidemenu ul li a, 
.category-sidemenu ul li ul li a {
    width: 100%;
    display: block;
}


.categ-active{
    color: red;
   font-weight: bold;
}

@media screen and (max-width: 991px) {
    .category-menu {
        position: relative !important;
        width: 100% !important;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
        top: 0px;
        order: 3;
    }

    .category-products {
        display: flex;
        justify-content: center !important;
        margin: auto;
        order: 1;
    }

    .pagination-wrapper {
        order: 2;
    }

    .category-products.product-notfound {
        min-height: 100px;
    }




 }