.productcategory-content, .category-products {
    display: flex;
    flex-wrap: wrap;
}

.category-menu {
    width: 25%;
    padding: 30px 0px;
    background: #ffffff;
}

.category-products {
    width: 75%;
}


.category-sidemenu {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    list-style-type: none;
    padding: 0;
    width: 100%;
}

    .category-sidemenu ul > li {
        padding: 4px;
        background: #e8e8e8;
        display: block;
    }

    .category-sidemenu ul li ul li {
        padding: 4px 8px;
    }

        .category-sidemenu ul li a, .category-sidemenu ul li ul li a {
            width: 100%;
            display: block;
        }




.categ-active {
    color: red;
    font-weight: bold;
}




.vertical-nav {
    width: 300px;
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}

.vertical-list {
    width: inherit;
}

    .vertical-list h5 {
        width: inherit;
        background: #ccc;
        margin: 0;
        border-bottom: 1px solid #77777752;
        font-size: 1rem;
    }

    .vertical-list a {
        color: #333;
    }

    .vertical-list h5 a {
        padding: .4rem .5rem;
        display: block;
        text-decoration: none;
    }

.categ-active {
    color: var(--main-color-orange) !important;
}

.vertical-sublist {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    background: #ccc;
}

    .vertical-sublist span {
        display: block;
        border-bottom: 1px solid #77777752;
        position: relative;
    }

        .vertical-sublist span a {
            display: block;
            padding: .3rem 0rem;
            text-decoration: none;
            font-size: 14px;
        }


        .vertical-sublist span::before {
            content: '';
            position: absolute;
            left: -12px;
            top: 50%;
            transform: translate(0%, -50%);
            width: 5px;
            height: 5px;
            border: 1px solid #333333;
            background: #333333;
        }

        .vertical-list h5:hover, .vertical-sublist span:hover {
            background: #b9b9b9;
        }





        .vertical-list h5:hover, .vertical-sublist span:hover {
            background: transparent;
        }



        .vertical-list h5 a:hover, .vertical-sublist span a:hover {
            color: #a50a07;
            font-weight: 500;
            text-decoration: underline;
        }


.vertical-list {
    width: inherit;
    background: #f2f2f2;
    border-bottom: 1px solid #e7e7e7;
    overflow-wrap: break-word;
}


.vertical-sublist {
    background: #e7e7e7;
    width: calc(100% - 36px);
    margin-left: auto;
    padding-left: 24px;
}

.vertical-list h5 {
    background: transparent;
    border-bottom: 1px solid transparent;
    position: relative;
    margin-left: 0px;
    padding-left: 16px;
    font-weight: bold;
}


    .vertical-list h5::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translate(0%, -50%);
        width: 5px;
        height: 5px;
        border: 1px solid #333;
        background: #333333;
    }

    .vertical-list h5 a {
        padding: .55rem .5rem;
        display: block;
        text-decoration: none;
    }


.vertical-sublist span {
    border-bottom: 1px solid transparent;
}

    .vertical-sublist span a {
        padding: .5rem 0rem;
    }

.category-products.product-notfound {
    justify-content: center;
    align-items: center;
    font-family: "Playball", cursive;
    background: #ffffff;
    color: var(--main-color-orange);
}