@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif);
/*a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
    color: #243559;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}*/









.menu-header {
    width: 100%;
    margin: 0 auto;
    background: var(--main-color-orange);
    color: #333333;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
    box-shadow: 0px 1px 10px #999;
}

.header-top {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.header-top-contacts span {
    font-size: 14px;
    margin-right: 12px;
}

nav {
    background-color: #ffffff;
    position: relative;
}



    nav ul {
        padding: 0;
        margin: 0 auto;
        list-style: none;
        position: relative;
        text-align: end;
        max-width: 1200px;
    }

        nav ul li {
            display: inline-block;
            /*background-color: #fff;*/
            text-align: left;
        }

            nav ul li .main-link--item {
                display: block;
                padding: 0 10px;
                color: #333333;
                font-size: 14px;
                line-height: 50px;
                text-decoration: none;
                text-transform: uppercase;
                border-bottom: 2px solid transparent;
                font-weight: bold;
            }

            nav ul li .sub-link--item {
                display: block;
                padding: 0 10px;
                color: #FFF;
                font-size: 13px;
                line-height: 40px;
                text-decoration: none;
                font-weight: 600;
                border-bottom: 1px solid #ffffff2e;
            }


    nav a:hover {
        background-color: #e8e8e8;
        border-bottom: 2px solid #fff;
        color: #333333 !important;
    }

    nav a{
        color: #333333 !important;
    }

    /* Hide Dropdowns by Default */
    nav ul ul {
        /* 	display: none; */
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity .3s ease-in;
    }

    nav ul .first-tier-dropdown {
        top: 52px; /* the height of the main nav */
        background: #ffffff;
    }


    /* Display Dropdowns on Hover */
    nav ul li:hover > ul {
        display: inherit;
        opacity: 1;
        pointer-events: initial;
    }

    /* Fisrt Tier Dropdown */
    nav ul ul li {
        min-width: 170px;
        float: none;
        display: list-item;
        position: relative;
    }

    /* Second, Third and more Tiers	*/
    nav ul ul ul li {
        position: relative;
        top: 0px;
        left: 0px;
    }


/* Change this in order to change the Dropdown symbol */
nav li > a:after {
    content: ' ▾';
}

nav li > a:only-child:after {
    content: '';
}


.second-tier--list > a:after {
    position: absolute;
    right: 12px;
}



.first-tier--list, .second-tier--list {
    position: relative;
}


.first-tier--dropdowm, .second-tier--dropdowm {
    position: absolute;
}

.first-tier-dropdown {
    left: 0;
}

.second-tier--dropdown {
    left: 170px;
    top: 0px;
    background: #ffffff;
}




.menu-svg {
    width: 32px;
    height: 35px;
    fill: #333333;
    display: none;
    cursor: pointer;
}

.header-top--logo a {
    position: relative;
}

    .header-top--logo a img {
        width: auto;
        height: 64px;
        margin-bottom: -56px;
        padding-bottom: 1px;
        position: relative;
        z-index: 2;
        /* border-radius: 50%;*/
    }


.search-input {
    width: 0;
    padding-right: 15px;
    transition: width .2s ease-in-out;
    border: none;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    background: transparent;
    color: #ffffff;
}

.seach-box {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 0;
    right: -12px;
    color: #ffffff;
    width: 20px;
    height: 20px;
    z-index: 2;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

    .search-icon i {
        font-size: 16px;
    }

.search-input:focus {
    width: 150px;
    padding-right: 40px;
    cursor: default;
    outline: none;
    border-bottom: 1px solid #333333;
}

    .search-input:focus + .search-icon {
        pointer-events: initial;
    }

.header-top-contacts span a {
    color: #ffffff;
    text-decoration: none;
    transition: color .2s ease-in;
}


    .header-top-contacts span a:hover {
        cursor: pointer;
        text-decoration: underline;
    }


svg {
    pointer-events: auto;
}

path {
    pointer-events: auto;
}


@media screen and (max-width: 991px) {
    .mycontainer {
        padding: 0px 15px;
    }

    .header-top-contacts {
        display: none;
    }


    .header-top {
        padding: 8px;
        background: #ffffff;
    }

    #mainMenu {
        height: 0;
        overflow: hidden;
        transition: height .2s ease-in;
        max-width: 1200px;
        margin: 0 auto;
    }

    .header-top--logo a img {
        width: 88px;
        height: 38px;
        margin-bottom: -3px;
        padding-bottom: 1px;
        position: relative;
    }

    .menu-svg {
        display: block;
        fill: #777777;
    }

    #mainMenu.show {
        height: 100vh;
        overflow-y: auto;
    }

    nav ul {
        position: inherit;
        top: 0;
        left: 0;
        text-align: center;
    }


        nav ul li {
            display: block;
            position: relative;
        }

        nav ul ul {
            display: block;
            height: 0;
            position: relative;
        }

        nav ul li ul li {
            display: block;
            position: relative;
        }

        nav ul .first-tier-dropdown {
            top: 0;
            right: 0;
        }

    .second-tier--dropdown {
        top: 0px;
        left: 0;
        right: 27px;
    }


    nav ul li:hover > ul {
        height: auto;
        pointer-events: initial;
    }

    li > a:after {
        position: absolute;
        right: 5% !important;
    }


}

.footer {
    width: 100%;
    background: #dfdfdf;
    padding: 20px 0px 0px 0px;
}

.footer-content-left {
    width: 62%;
}

.footer-content-right {
    width: 38%;
}

.footer-content, .footer-content-left {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.company-info, .company-links {
    width: 50%;
}

.about-company, .about-company p, .about-company p span, .about-company h2 {
    background-color: transparent !important;
    font-size: 13px !important;
    font-weight: normal !important;
    margin-bottom: 0 !important;
    line-height: 24px!important;
}



    .company-info img {
        width: 160px;
        margin-bottom: 1rem;
    }

    .company-info p {
        font-size: 14px;
        color: #262626;
        line-height: 24px;
    }

.company-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 2rem;
}


.company-links--fast {
    min-width: 100px;
}


.socialmedia-content h5 {
    margin-bottom: 0;
}

.socialmedia-icons {
    display: flex;
}

.footer-link-title {
    color: #333;
    font-size: 15px;
    font-weight: bold;
}

.footer-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.footer-list li, .footer-content-right--list li{
  margin-bottom: 8px;
  display: flex;
 }



    .footer-list li > a {
        padding: 0;
        text-decoration: none;
        color: #262626;
        font-size: 14px;
        display: block;
        transition: all .25s cubic-bezier(.645,.045,.355,1);
    }

        .footer-list li > a:hover {
            -webkit-transform: translateX(3px);
                    transform: translateX(3px);
            color: #cb7100;
        }


.socialmedia-icons span {
    margin-right: 8px;
}

.footer-socialmedia-list {
    list-style-type: none;
    padding: 0;
    margin-top: 12px;
    display: flex;
}

    .footer-socialmedia-list li a {
        margin-right: 8px;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        transition: all .25s cubic-bezier(.645,.045,.355,1);
        display: inline-block;
    }

        .footer-socialmedia-list li a:hover {
            -webkit-transform: translateY(-3px);
                    transform: translateY(-3px);
        }



.facebook i,
.twitter i,
.instagram i,
.youtube i {
    font-size: 2rem;
}

.facebook i {
    color: #1b4195;
}

.twitter i {
    color: #31ade1;
}

.instagram i {
    color: #db2f4f;
}

.youtube i {
    color: #BD3A2C;
}

.footer-content-right--list {
    list-style-type: none;
    padding: 0;
    color: #777777;
    margin-bottom: 2rem;
}

    .footer-content-right--list li i {
        width: 36px;
        font-size: 1.5rem;
        color: #777777;
    }

 .footer-content-right--list li span {
        font-size: 14px;
        font-weight: 500;
        color: #262626;
 }

.footer-address{
    padding-left: 8px;
}

.footer-content-right--list li span .fa-envelope {
    margin-left: 8rem !important;
}

.footer-content-right {
    padding-top: 2rem;
}

.footer-link-title{
    margin-bottom: 16px;
}

.footer-link-title1 {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.footer-content-right p {
    font-size: 13px;
    font-weight: bold;
    color: #333;
}

footer {
    background: var(--main-color-orange);
    padding: 12px;

}

    footer p {
        font-size: 13px;
        color: #dce4f0;
        text-align: center;
    }


@media screen and (max-width: 991px) {
    .footer-content {
        padding: 0px 15px;
        flex-direction: column;
    }

    .footer-content-left, .footer-content-right {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .company-info, .company-links {
        width: 100%;
    }
}

.floatingicons-container {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.floatingicons-container ul {
  list-style-type: none;

}

 .floatingicons-container ul li a {
        width: 45px;
        height: 45px;
        border-radius: 50px;
        color: white;
        display: flex;
        justify-content:center;
        align-items: center;
        margin-top: 6px;
 }

        .floatingicons-container ul li a:hover {
            text-decoration: none;
        }

        .floatingicons-container ul li a:hover  i {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
        }


  .floatingicons-container ul li a i {
        font-size: 1.8rem;
        transition: all .3s ease-out;
  }

.ft-phone {
    background: #337ab7!important;
}
.ft-whatsapp {
    background: #4dc247;
}
.ft-telegram {
    background: #27A7E5;
}
.ft-instagram {
    background: #fd3a67;
}


@media screen and (max-width: 991px) {
    .floatingicons-container {
        bottom: 30px;
        left: -20px;
        z-index: 10;
        width: 76px;
    }

        .floatingicons-container ul li a {
            width: 35px;
            height: 35px;
        }

       .floatingicons-container ul li a i {
          font-size: 1.2rem;
       }

}




.mybtn {
    box-sizing: border-box;
    -webkit-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 0.6rem;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    text-align: center!important;
    line-height: 1;
    padding: .8rem 2rem;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

 .mybtn:hover, .mybtn:focus {
    color: #fff;
    outline: 0;
    text-decoration: none;
 }


.mybtn-default {
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    
}
    .mybtn-default:hover {
        box-shadow: 0 0 40px 40px var(--main-color-orange) inset;
        border: 2px solid #cb7100;
    }



@media (max-width: 600px) {
    .mybtn {
        padding: .7rem 1.2rem;
    }
}


.hero-slider {
    padding-top: 70px;
}


@media (min-width: 992px) {
    .slider, .slide {
        height: 530px;
    }
}


/*.slide-content {
    position: absolute;
    left: 100px;
    bottom: 100px;
  
}
*/

.slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
}


.slide-content{
    color: #ffffff;
}


.slide-description {
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-family: "Playball", cursive;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.hero-calltoaction {
   display: flex;
}

   .hero-calltoaction a{
       margin-right: 10px;
  }


 .nextButton svg polygon, .previousButton svg polygon {
     fill: #ffb457;
     -webkit-transform: rotate(180deg, 10, 15);
             transform: rotate(180deg, 10, 15);
  }

.slide h1 {
    transition: all 0.3s ease;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.slide button {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide a {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}




.slide p {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide section * {
    transition: all 0.3s ease;
}

.slide section img {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 0;
}

.slide section span {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
}

    .slide section span strong {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
    }

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition-delay: .9s;
    opacity: 1;
}


.slide.current a.mybtn-default {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition-delay: .3s!important;
    opacity: 1;
}



.slide.animateIn.previous p,
.slide.animateIn.next p {
    transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
    transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
    transition-delay: 1.4s;
}

    .slide.animateIn.previous section span strong,
    .slide.animateIn.next section span strong {
        transition-delay: 1.5s;
    }

.slide.animateOut h1 {
    transition-delay: .3s;
}

.slide.animateOut p {
    transition-delay: .2s;
}

.slide.animateOut section span {
    transition-delay: .1s;
}

    .slide.animateOut section span strong {
        transition-delay: 0s;
    }





.slide-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
   
}

.slide-content-desc {
    position: absolute;
    bottom: 100px;
}

@media (max-width: 1300px) {
    .slide-content {
        padding-left: 60px;
    }
}



    @media (max-width: 991px) {

        .slide-content-desc {
            position: absolute;
            bottom: 100px;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            text-align: center;
        }

        .hero-slider {
            padding-top: 50px;
        }

        .slide-content {
            padding: 10px;
        }

        .hero-calltoaction {
            justify-content: center;
        }

    }



    .slide-content h1, .slide-description {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    @media (max-width: 600px) {

        .slide-content {
            padding: 5px;
            left: 50%;
            -webkit-transform: translate(-50%, -0%);
                    transform: translate(-50%, -0%);
        }

        .slider {
            height: 330px;
        }

        .slide-content h1 {
            font-size: 24px;
        }


        .slide-description {
            font-size: 1.1rem;
        }


        .slider a.previousButton {
            left: -10px;
        }

        .slider a.nextButton {
            right: -10px;
        }
    }




.featuredCard {
    width: 280px;
    height: 400px;
    background: #ffffff;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #f2f2f2;
    position: relative;
    overflow: hidden;
    border-radius: 4;
    margin: 10px;
}


.featuredCard-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgba(0,0,0,0.5);
    opacity: 0;
    transition: opacity .2s ease-in-out;
    pointer-events: none;
}


.featuredCard-indirim {
    position: absolute;
    -webkit-transform: rotate( 90deg);
            transform: rotate( 90deg);
    right: -34px;
    top: 34px;
    background: red;
    color: #fff;
    font-weight: bold;
    padding: 0px 15px;
    border-radius: 0px 0px 16px 0px;
}


 .featuredCard-overlay button {
        font-weight: bold;
    }

.featuredCard:hover .featuredCard-overlay {
    opacity: 1;
    pointer-events: initial;
}

.featuredCard-stats {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: #ffffff;
    width: 100%;
    padding: 15px;
    transition: bottom .3s ease-in-out;
    border-top: 1px solid #eeeeee;
}

.featuredCard-stats--title {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

.featuredCard-stats--price {
    color: #ff0000;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

.featuredCard:hover .featuredCard-stats {
    bottom: 0;
}



.featuredCard-overlay--img {
    width: 0;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}


    .featuredCard-overlay--img.show-img {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,0.8);
        z-index: 999;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

.overlay-img--box {
    max-width: 600px;
}

.show-img button {
    border: none;
    outline: none;
    color: #ffffff;
    position: inherit;
    top: 50px;
    right: 50px;
    background: transparent;
}

.btn-incele {
    margin-right: 5px;
}

.overlay-img--box img {
    width: 100%;
    object-fit: contain;
    height: auto;
}



@media screen and (max-width: 600px) {
    .featuredCard {
        width: 90%;
        margin: 10px auto;
    }
}




.featured {
    width: 100%;
    background: #f2f2f2;
    padding: 80px 20px;
    margin-bottom: 2.5rem;
}

.featured-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}



@media screen and (max-width: 600px) {
    .featured-content {
        flex-direction: column;
    }
}


.mycard {
    position: relative;
    width: 32%;
    padding-bottom: 15px;
    margin: 8px 8px 12px 8px;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px; 
}
    .mycard .mycard-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .mycard .mycard-img-box {
        width: 100%;
        height: 230px;
        overflow: hidden;
    }

    .mycard .mycard-img-box .mycard-img {
        width: 100%;
        height: 100%;
        transition: all .3s ease-in-out;
        pointer-events: none;
    }

.mycard-link:hover + .mycard-img-box .mycard-img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.mycard-body {
    width: 100%;
    padding: 10px;
}

    .mycard-body h1 {
        color: #333333;
        font-size: 1.5rem;
        margin-top: 12px;
        font-weight: bold;
    }

    .mycard-body h5 {
        color: var(--main-color-orange);
        font-size: .8rem;
        margin-right: 5px;
        font-weight:bold;
    }

    .mycard-body p {
        color: #777777;
        font-size: 15px;
    }

.products-link {
    display: block;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    width: 130px;
    position: relative;
    border: 1px solid var(--main-color-orange);
    border-radius: 4px;
    color: var(--main-color-orange);
    text-decoration: none !important;
    transition: background .3s ease-in-out;
    height: 40px;
    padding-top: 8px;
    margin-left: 10px;
}

    .products-link:hover {
        background: var(--main-color-orange);
        color: #ffffff;
    }

   .products-link::after {
       position: absolute;
       content: '\2192';
       top: -2px;
       right: 24px;
       width: 16px;
       height: 16px;
       font-size: 1.5rem;
       transition: right .3s ease-in-out;
    }

  .products-link:hover:after {
        right: 18px;
    }

.mycard-body .franchise {
    display: flex;
    flex-wrap: wrap;
}

.product-category--slide .slick-slider .slick-list .slick-track .slick-slide  div .mycard{
    width: auto;
    margin: 8px;
}


@media (max-width: 600px) {
    .mycard {
        width: 100%;
        margin: 10px auto;
    }
}

@media (min-width: 600px) and (max-width: 991px) {
    .mycard {
        width: 48%;
        margin: 10px auto;
    }
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #fbfbfb!important;
}

.product-category--slide .slick-slider .slick-next:before, 
.product-category--slide .slick-slider .slick-prev:before {
    font-size: 24px;
    line-height: 1;
    opacity: .75;
    color: #e22a2a;
}


.product-category--slide .slick-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #e22a2a!important;
}



@media screen and (max-width: 991px) {


    .product-category--slide .slick-slider .slick-next:before,
    .product-category--slide .slick-slider .slick-prev:before {
        opacity: 0;
    }
}



@media screen and (max-width: 600px) {

    .product-category--slide {
        overflow-x: hidden;
        min-height: 554px;
    }

}


.product-categories--wrapper {
    padding: 40px 20px;
   background: #ffffff;
}

.product-categories {
    display: flex;
    flex-wrap: wrap;
}


.product-categories {
    margin-bottom: 3rem;
}

.product-category--slide{
    padding-bottom: 4rem;
}
/*aboutus style*/

.aboutus {
    width: 100%;
    margin-bottom: 3rem;
    padding: 40px 30px;
}

.aboutus-content {
    display: flex;
}

.aboutus-content--text {
    width: 60%;
    color: #333333;
    padding: 142px 30px 30px 30px;
}



    .aboutus-content--text p {
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
    }

.aboutus-content--img {
    width: 40%;
    height: 320px;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-left: 100px;
    position: relative;
    z-index: 1;
}

    .aboutus-content--img::before {
        content: '';
        position: absolute;
        top: -84px;
        left: -100px;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: var(--main-color-orange);
        border-radius: 16px;
    }


    .aboutus-content--img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
        position: relative;
        box-shadow: 1px 1px 16px 0 rgb(48 50 50 / 63%);
    }




@media screen and (max-width: 991px) {

    .aboutus {
        margin-bottom: 2rem;
        padding: 40px 0px;
    }

    .aboutus-content {
        flex-direction: column;
        justify-content: center;
    }


    .aboutus-content--text, .aboutus-content--img {
        width: 95%;
        margin: auto;
    }


    .aboutus-content--text {
        padding: 30px 0px 0px 0px;
    }

      .aboutus-content--img::before {
            top: 0;
            left: 0;
      }

}


.franchise-card {
    position: relative;
    width: 230px;
    height: 100px;
    margin: 5px;
    border: 1px solid #ccc;
    z-index: 2;
    border-radius: 4px;
    overflow: hidden;
    background: #ffffff;
}

.franchise-card-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.franchise-card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: -webkit-filter .3s ease-in-out;
    transition: filter .3s ease-in-out;
    transition: filter .3s ease-in-out, -webkit-filter .3s ease-in-out;
}

.franchise-card:hover  img {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}


.franchise-card:hover {
    border: 1px solid #243559;
}

@media screen and (max-width: 600px) {
    .franchise-card {
        width: 43%;
        height: 72px;
    }
}


.franchise-content {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px;
    justify-content: center;
}

.loader-wrapper
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity .3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: #fff;
    pointer-events: none;
}

    .loader-wrapper.on-loading {
        opacity: 1;
        pointer-events: initial;
    }

    .loader-content  svg {
        width: 100px;
        height: 100px;
        margin: 10px;
        display: inline-block;
    }

.cls-1, .cls-2 {
    fill: none;
    stroke-linecap: bevel;
    stroke-linejoin: round;
}

.cls-1 {
    stroke-width: 2px;
}

.cls-2 {
    fill: none;
    stroke: #777777;
    stroke-width: 4px;
}


.fa.fa-spinner {
    color: var(--main-color-orange);
}


.reference-container{
    display: flex; 
    flex-wrap:wrap;
    justify-content: center;
    padding: 30px 4px 40px 4px;
}

.referenceCard img {
    object-fit: contain;
    width: 100%;
    height: 180px;
    min-height: 180px;
    min-width:100%;
    background: #cccccc;
}
.referenceCard {
    position: relative;
    width: 290px;
    height: auto;
    border-radius: 12px;
    background: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    overflow: hidden;
    margin: 4px;
}

.referenceCard-footer{
   /* position: absolute;
    left: 0;
    bottom: 0;*/
    background: #ffffff;
    width: 100%;
    padding: 12px;
}

    .referenceCard-footer h1, .referenceCard-footer p {
        display: block;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.referenceCard-footer h1{
    font-size: 1.2rem;
    font-weight: bold;
}

    .referenceCard-footer p {
        font-size: 14px;
    
    }

@media screen and (max-width: 600px) {
    .reference-container {
      justify-content:center;
    }
}

.Pagebreadcrumb-container{
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}


.breadcrumb-box h1 {
    text-align: center;
    margin-top: 50px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
}

.Pagebreadcrumb-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 53, 89, 0.75);
}

.breadcrumb-box {
    max-width: 360px;
    padding: 60px 0px 40px 0px;
    margin: 0 auto;
}


.breadcrumb-box nav{
   width: -webkit-fit-content!important;
   width: fit-content!important;
   margin: auto;
}

  .breadcrumb-box nav .breadcrumb-item {
     border-bottom: 2px solid transparent;
 }

@media screen and (max-width: 991px) {
    .breadcrumb-box h1 {
        font-size: 20px;
        margin-top: 34px;
    }

    .breadcrumb-box {
        padding: 40px 0px;
    }

    .breadcrumb {
        padding: 0.3rem 1rem;
    }

}
@font-face {
    font-family: "Material Icons Outlined";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v54/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format("woff2");
}

.material-icons-outlined {
    font-family: "Material Icons Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}


.contact_wrapper {
    display: flex;
    background: white;
    padding: 40px 25px;
    box-shadow: 0 0px 4px rgb(0 0 0 / 18%);
    position: relative;
    width: 700px;
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper {
        width: 80%;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .contact_wrapper {
        width: 90%;
        flex-direction: column;
        padding-top: 0px;
    }
}

.contact_wrapper .contact_info {
    position: relative;
    width: 260px;
    flex: 0 0 230px;
    background: var(--main-color-orange);
    color: #ffffff;
    margin-left: calc(-230px / 2 - 30px);
    padding: 25px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper .contact_info {
        width: 100%;
        flex: 0 0 100%;
        margin-left: -50px;
        margin-top: -15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .contact_wrapper .contact_info {
        width: 100%;
        flex: 0 0 100%;
        margin-left: 0px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
}

.contact_wrapper .contact_info .title {
    font-size: 24px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 30px;
}

.contact_wrapper .contact_info .icons_wrapp {
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-bottom: 30px;
}

    .contact_wrapper .contact_info .icons_wrapp li {
        display: flex;
        align-items: baseline;
        margin-bottom: 25px;
    }

        .contact_wrapper .contact_info .icons_wrapp li .icon .material-icons-outlined {
            vertical-align: middle;
            font-size: 24px;
            width: 32px;
        }

        .contact_wrapper .contact_info .icons_wrapp li .text {
            vertical-align: middle;
            line-height: 1.4;
            font-size: 14px;
        }

.contact_wrapper .contact_info .soci_wrap {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    position: absolute;
    bottom: 25px;
}

    .contact_wrapper .contact_info .soci_wrap li {
        margin: 0px 5px;
    }

        .contact_wrapper .contact_info .soci_wrap li a {
            color: #f4f4f4;
            text-decoration: none;
            transition: all 0.3s ease;
        }

            .contact_wrapper .contact_info .soci_wrap li a:hover {
                color: #fff38f;
            }

            .contact_wrapper .contact_info .soci_wrap li a .material-icons-outlined {
                font-size: 32px;
            }

.contact_wrapper .contact_msg {
    width: calc(100% - 230px / 2 - 55px);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 230px / 2 - 55px);
    padding: 25px 30px;
    padding-bottom: 0px;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper .contact_msg {
        width: calc(100% - 25px - 30px);
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 25px - 30px);
    }
}

@media (max-width: 767px) {
    .contact_wrapper .contact_msg {
        width: calc(100%);
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100%);
        padding: 25px 0px;
    }
}

.contact_wrapper .contact_msg .title {
    font-size: 24px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 0 15px;
    color: #095a54;
}

.contact_wrapper .contact_msg .form_fild {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

    .contact_wrapper .contact_msg .form_fild .input_group {
        position: relative;
        margin-bottom: 25px;
        padding: 0 15px;
    }

        .contact_wrapper .contact_msg .form_fild .input_group.w_50 {
            width: calc(50% - 30px);
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 30px);
        }

@media (max-width: 767px) {
    .contact_wrapper .contact_msg .form_fild .input_group.w_50 {
        width: calc(100% - 30px);
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 30px);
    }
}

.contact_wrapper .contact_msg .form_fild .input_group.w_100 {
    width: calc(100% - 30px);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 30px);
}

.contact_wrapper .contact_msg .form_fild .input_group .input {
    width: 100%;
    min-height: 30px;
    padding: 5px 0px;
    border: none;
    font-size: 18px;
    color: #444;
    outline: none;
    font-weight: 400;
    border-bottom: solid 2px #e9e9e9;
}

    .contact_wrapper .contact_msg .form_fild .input_group .input.input_textarea {
        min-width: 100%;
        min-height: 75px;
        max-height: 75px;
        max-width: 100%;
    }

    .contact_wrapper .contact_msg .form_fild .input_group .input:hover {
        border-bottom-color: #ddd;
    }

    .contact_wrapper .contact_msg .form_fild .input_group .input:focus {
        border-bottom-color: #aaa;
    }

        .contact_wrapper .contact_msg .form_fild .input_group .input:valid ~ .placeholder, .contact_wrapper .contact_msg .form_fild .input_group .input:focus ~ .placeholder {
            top: -50px;
            font-size: 12px !important;
            color: #095a54;
        }

            .contact_wrapper .contact_msg .form_fild .input_group .input:valid ~ .placeholder.textarea, .contact_wrapper .contact_msg .form_fild .input_group .input:focus ~ .placeholder.textarea {
                top: -12px;
            }

.contact_wrapper .contact_msg .form_fild .input_group .placeholder {
    position: absolute;
    left: 15px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #bbb;
    font-weight: 400;
    transition: all 0.3s ease;
    pointer-events: none;
}

    .contact_wrapper .contact_msg .form_fild .input_group .placeholder.textarea {
        align-items: baseline;
    }

.contact_wrapper .contact_msg .form_fild .input_group .btn {
    padding: 12px;
    border: none;
    border-radius: 0px;
    background-color: var(--main-color-orange);
    color: white;
    min-width: 120px;
    outline: none;
    transition: all 0.3s ease;
}

    .contact_wrapper .contact_msg .form_fild .input_group .btn:hover {
        background-color: #e46112;
    }

    .contact_wrapper .contact_msg .form_fild .input_group .btn:active {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }


.map-container {
    width: 100%;
}

    .map-container iframe {
        width: 100% !important;
    }

.gallery-wrapper {
    padding: 30px;
}


.myrow {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    justify-content: center;
}

    .myrow img {
        margin: 4px;
        vertical-align: middle;
        width: 32%;
        cursor: pointer;
        opacity: .9;
        transition: opacity .3s ease-in-out;
        object-fit: cover;
    }

        .myrow img:hover {
            opacity: 1;
        }

@media screen and (max-width: 800px) {
    .myrow img {
        width: 47%;
    }
}

@media screen and (max-width: 600px) {

    .gallery-wrapper{
      padding: 20px 0px;
    }
    .myrow img {
        width: 47%;
    }


}



.slider-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in;
    overflow-y: auto;
    padding: 20px;
}


    .slider-overlay.show {
        opacity: 1;
        pointer-events: all;
        display: flex;
    }


.slider-light--content {
    max-width: 991px;
    height: auto;
    margin: auto;
}

.light-close {
    display: inline-block;
    margin-right: auto;
    color: #ffffff;
    position: inherit;
    right: 50px;
    top: 50px;
    cursor: pointer;
    z-index: 999;
}

    .light-close i {
        font-size: 1.5rem;
    }


@media screen and (max-width: 991px) {
    .slider-light--content {
        max-width: 80%;
    }
}
.pagination-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2rem 0rem;
}


.page-link {
    border-bottom: 2px solid transparent;
}
.vertical-nav {
    width: 300px;
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}

.vertical-list{
    width:inherit;
}
    .vertical-list h5 {
        width: inherit;
        background: #ccc;
        margin: 0;
        border-bottom: 1px solid #77777752;
        font-size: 1rem;
    }
    .vertical-list  a {
      color: #333;
    }
 .vertical-list h5 a{
        padding: .4rem .5rem;
        display: block;
        text-decoration: none;
    }

.categ-active {
    color: var(--main-color-orange) !important;
}

.vertical-sublist {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    background: #ccc;
   
}

    .vertical-sublist span {
        display: block;
        border-bottom: 1px solid #77777752;
        position: relative;
    }

    .vertical-sublist span a {
        display: block;
        padding: .3rem 0rem;
        text-decoration: none;
        font-size: 14px;
    }


        .vertical-sublist span::before {
            content: '';
            position: absolute;
            left: -12px;
            top: 50%;
            -webkit-transform: translate(0%, -50%);
                    transform: translate(0%, -50%);
            width: 5px;
            height: 5px;
            border: 1px solid #333333;
            background: #333333;
        }

        .vertical-list h5:hover, .vertical-sublist span:hover {
            background: #b9b9b9;
        }





        .vertical-list h5:hover, .vertical-sublist span:hover {
            background: transparent;
        }



        .vertical-list h5 a:hover, .vertical-sublist span a:hover {
            color: #a50a07;
            font-weight: 500;
            text-decoration: underline;
        }


.vertical-list {
    width: inherit;
    background: #f2f2f2;
    border-bottom: 1px solid #e7e7e7;
    overflow-wrap: break-word;
}


.vertical-sublist {
    background: #e7e7e7;
    width: calc(100% - 36px);
    margin-left: auto;
    padding-left: 24px;
}

.vertical-list h5 {
    background: transparent;
    border-bottom: 1px solid transparent;
    position: relative;
    margin-left: 0px;
    padding-left: 16px;
    font-weight: bold;
}


    .vertical-list h5::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 50%;
        -webkit-transform: translate(0%, -50%);
                transform: translate(0%, -50%);
        width: 5px;
        height: 5px;
        border: 1px solid #333;
        background: #333333;
    }

    .vertical-list h5 a {
        padding: .55rem .5rem;
        display: block;
        text-decoration: none;
    }


.vertical-sublist span {
    border-bottom: 1px solid transparent;
}

    .vertical-sublist span a {
        padding: .5rem 0rem;
    }


.productcategory-content, .category-products {
    display: flex;
    flex-wrap: wrap;
   
}

.category-menu {
    width: 25%;
    padding: 30px 0px;
    background: #ffffff;
    position: sticky;
    position: -webkit-sticky;
    top: 70px;
}

.category-products {
    width: 75%;
    padding-top: 20px;
}

    .category-products.product-notfound {
        justify-content: center;
        align-items: center;
        font-family: "Playball", cursive;
        background: #ffffff;
        color: var(--main-color-orange);
    }

.category-sidemenu {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    list-style-type: none;
    padding: 0;
    width: 100%;
}

    .category-sidemenu ul > li {
        padding: 4px;
        background: #e8e8e8;
        display: block;
    }

    .category-sidemenu ul li ul li {
        padding: 4px 8px;
       
    }

.category-sidemenu ul li a, 
.category-sidemenu ul li ul li a {
    width: 100%;
    display: block;
}


.categ-active{
    color: red;
   font-weight: bold;
}

@media screen and (max-width: 991px) {
    .category-menu {
        position: relative !important;
        width: 100% !important;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
        top: 0px;
        order: 3;
    }

    .category-products {
        display: flex;
        justify-content: center !important;
        margin: auto;
        order: 1;
    }

    .pagination-wrapper {
        order: 2;
    }

    .category-products.product-notfound {
        min-height: 100px;
    }




 }
.social-share--container {
    margin: 30px 0px;
}

.product-detail-container {
    display: flex;
    margin-top: 15px!important;
    margin-bottom: 100px!important;
    padding: 20px 0px;
    background: #ffffff;
}

.documnet-container{
    width: 70%;
}

.product-info{
    width: 30%;
    padding: 10px 20px;
}

    .product-info h1 {
        font-size: 2.2rem;
        font-weight: 700;
        margin: 0px 0px;
        line-height: 1.4em;
        color: #243559;
        margin-bottom: 15px;

    }

    .product-info p{
        margin-bottom: 30px;
    }
    .product-info--price {
        position: relative;
        color: #7fc241;
        font-size: 28px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 15px;
        text-decoration: inherit;
    }

.product-info--stock{
    margin: 20px 0px;
}
.Product-share h5 {
    color: #243559;
    font-size: 16px;
    font-weight: 500;
}


.product-info--stock {
    color: #243559;
    font-size: 16px;
    font-weight: 500;
}


.detail-text {
    color: #8e8e8e;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
}

.smilarproduct{
    display: flex;
    flex-wrap:wrap;
    background: #ffffff;
    padding: 20px 0px;
    margin-bottom: 2rem;
}

.smilarproduct-container h1 {
    font-size: 2rem;
    font-weight: 600;
}

.product-detail-btn a {
    text-align: center !important;
    display: block;
    background: var(--main-color-orange)!important;
}




@media (max-width: 991px) {
    .product-detail-container {
        flex-direction: column;
        margin-top: 20px !important;
    }
    .documnet-container, .product-info {
        width: 90%;
        margin: 15px auto;
    }

    .product-info h1 {
        font-size: 1.5rem;
    }

    .smilarproduct-container h1{
        font-size: 1.5rem;
    }

}

.productcategory-content, .category-products {
    display: flex;
    flex-wrap: wrap;
}

.category-menu {
    width: 25%;
    padding: 30px 0px;
    background: #ffffff;
}

.category-products {
    width: 75%;
}


.category-sidemenu {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    list-style-type: none;
    padding: 0;
    width: 100%;
}

    .category-sidemenu ul > li {
        padding: 4px;
        background: #e8e8e8;
        display: block;
    }

    .category-sidemenu ul li ul li {
        padding: 4px 8px;
    }

        .category-sidemenu ul li a, .category-sidemenu ul li ul li a {
            width: 100%;
            display: block;
        }




.categ-active {
    color: red;
    font-weight: bold;
}




.vertical-nav {
    width: 300px;
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}

.vertical-list {
    width: inherit;
}

    .vertical-list h5 {
        width: inherit;
        background: #ccc;
        margin: 0;
        border-bottom: 1px solid #77777752;
        font-size: 1rem;
    }

    .vertical-list a {
        color: #333;
    }

    .vertical-list h5 a {
        padding: .4rem .5rem;
        display: block;
        text-decoration: none;
    }

.categ-active {
    color: var(--main-color-orange) !important;
}

.vertical-sublist {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    background: #ccc;
}

    .vertical-sublist span {
        display: block;
        border-bottom: 1px solid #77777752;
        position: relative;
    }

        .vertical-sublist span a {
            display: block;
            padding: .3rem 0rem;
            text-decoration: none;
            font-size: 14px;
        }


        .vertical-sublist span::before {
            content: '';
            position: absolute;
            left: -12px;
            top: 50%;
            -webkit-transform: translate(0%, -50%);
                    transform: translate(0%, -50%);
            width: 5px;
            height: 5px;
            border: 1px solid #333333;
            background: #333333;
        }

        .vertical-list h5:hover, .vertical-sublist span:hover {
            background: #b9b9b9;
        }





        .vertical-list h5:hover, .vertical-sublist span:hover {
            background: transparent;
        }



        .vertical-list h5 a:hover, .vertical-sublist span a:hover {
            color: #a50a07;
            font-weight: 500;
            text-decoration: underline;
        }


.vertical-list {
    width: inherit;
    background: #f2f2f2;
    border-bottom: 1px solid #e7e7e7;
    overflow-wrap: break-word;
}


.vertical-sublist {
    background: #e7e7e7;
    width: calc(100% - 36px);
    margin-left: auto;
    padding-left: 24px;
}

.vertical-list h5 {
    background: transparent;
    border-bottom: 1px solid transparent;
    position: relative;
    margin-left: 0px;
    padding-left: 16px;
    font-weight: bold;
}


    .vertical-list h5::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 50%;
        -webkit-transform: translate(0%, -50%);
                transform: translate(0%, -50%);
        width: 5px;
        height: 5px;
        border: 1px solid #333;
        background: #333333;
    }

    .vertical-list h5 a {
        padding: .55rem .5rem;
        display: block;
        text-decoration: none;
    }


.vertical-sublist span {
    border-bottom: 1px solid transparent;
}

    .vertical-sublist span a {
        padding: .5rem 0rem;
    }

.category-products.product-notfound {
    justify-content: center;
    align-items: center;
    font-family: "Playball", cursive;
    background: #ffffff;
    color: var(--main-color-orange);
}

.notfound-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #005182;
}

.notfound-container{
    max-width: 400px;
    text-align: center;
}

.notfound-container img{
    max-width: 100%;
    height: auto;
}

    .notfound-container p{
       color: #ffffff;
       margin: 20px;
    }


.notfound-container a{
    display:inline-block;
    text-align: center!important;
}
/* Provide sufficient contrast against white background */

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


html {
    scroll-behavior: smooth;
}


:root {
    /*  --main-bg-blue: #3962a2;
    --main-color-orange: #f27021;*/
     --main-bg-blue: #3962a2;
    --main-color-orange: #e22a2a;
}


body {
    font-size: 16px;
    word-wrap: break-word !important;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    width: 100%;
}


.mycontainer {
    max-width: 1200px;
    margin: 0 auto;
}


.section-title {
    /*color: var(--main-bg-blue);*/
    color: #333333;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
    position: relative;
}


    .section-title::before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
        width: 60px;
        height: 3px;
        background: #e22a2a;
        background: var(--main-color-orange);
    }


    .section-title.text-left::before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0%!important;
        -webkit-transform: translate(0%, 0%);
                transform: translate(0%, 0%);
    }

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    border-radius: 10px;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    background-color: #333333;
}

.section-ptb{
    padding: 4rem 0rem;
}
@media screen and (max-width: 991px) {
    .mycontainer {
        padding: 0px 15px;
    }
}
