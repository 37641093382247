
.franchise-card {
    position: relative;
    width: 230px;
    height: 100px;
    margin: 5px;
    border: 1px solid #ccc;
    z-index: 2;
    border-radius: 4px;
    overflow: hidden;
    background: #ffffff;
}

.franchise-card-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.franchise-card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: grayscale(100%);
    transition: filter .3s ease-in-out;
}

.franchise-card:hover  img {
    filter: grayscale(0%);
}


.franchise-card:hover {
    border: 1px solid #243559;
}

@media screen and (max-width: 600px) {
    .franchise-card {
        width: 43%;
        height: 72px;
    }
}