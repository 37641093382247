.featured {
    width: 100%;
    background: #f2f2f2;
    padding: 80px 20px;
    margin-bottom: 2.5rem;
}

.featured-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}



@media screen and (max-width: 600px) {
    .featured-content {
        flex-direction: column;
    }
}
