
.mycard {
    position: relative;
    width: 32%;
    padding-bottom: 15px;
    margin: 8px 8px 12px 8px;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px; 
}
    .mycard .mycard-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .mycard .mycard-img-box {
        width: 100%;
        height: 230px;
        overflow: hidden;
    }

    .mycard .mycard-img-box .mycard-img {
        width: 100%;
        height: 100%;
        transition: all .3s ease-in-out;
        pointer-events: none;
    }

.mycard-link:hover + .mycard-img-box .mycard-img {
    transform: scale(1.1);
}

.mycard-body {
    width: 100%;
    padding: 10px;
}

    .mycard-body h1 {
        color: #333333;
        font-size: 1.5rem;
        margin-top: 12px;
        font-weight: bold;
    }

    .mycard-body h5 {
        color: var(--main-color-orange);
        font-size: .8rem;
        margin-right: 5px;
        font-weight:bold;
    }

    .mycard-body p {
        color: #777777;
        font-size: 15px;
    }

.products-link {
    display: block;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    width: 130px;
    position: relative;
    border: 1px solid var(--main-color-orange);
    border-radius: 4px;
    color: var(--main-color-orange);
    text-decoration: none !important;
    transition: background .3s ease-in-out;
    height: 40px;
    padding-top: 8px;
    margin-left: 10px;
}

    .products-link:hover {
        background: var(--main-color-orange);
        color: #ffffff;
    }

   .products-link::after {
       position: absolute;
       content: '\2192';
       top: -2px;
       right: 24px;
       width: 16px;
       height: 16px;
       font-size: 1.5rem;
       transition: right .3s ease-in-out;
    }

  .products-link:hover:after {
        right: 18px;
    }

.mycard-body .franchise {
    display: flex;
    flex-wrap: wrap;
}

.product-category--slide .slick-slider .slick-list .slick-track .slick-slide  div .mycard{
    width: auto;
    margin: 8px;
}


@media (max-width: 600px) {
    .mycard {
        width: 100%;
        margin: 10px auto;
    }
}

@media (min-width: 600px) and (max-width: 991px) {
    .mycard {
        width: 48%;
        margin: 10px auto;
    }
}
