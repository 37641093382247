

.hero-slider {
    padding-top: 70px;
}


@media (min-width: 992px) {
    .slider, .slide {
        height: 530px;
    }
}


/*.slide-content {
    position: absolute;
    left: 100px;
    bottom: 100px;
  
}
*/

.slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
}


.slide-content{
    color: #ffffff;
}


.slide-description {
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-family: "Playball", cursive;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.hero-calltoaction {
   display: flex;
}

   .hero-calltoaction a{
       margin-right: 10px;
  }


 .nextButton svg polygon, .previousButton svg polygon {
     fill: #ffb457;
     transform: rotate(180deg, 10, 15);
  }

.slide h1 {
    transition: all 0.3s ease;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.slide button {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide a {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}




.slide p {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide section * {
    transition: all 0.3s ease;
}

.slide section img {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 0;
}

.slide section span {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
}

    .slide section span strong {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
    }

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: .5s;
    transition-delay: .9s;
    opacity: 1;
}


.slide.current a.mybtn-default {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: .3s!important;
    transition-delay: .3s!important;
    opacity: 1;
}



.slide.animateIn.previous p,
.slide.animateIn.next p {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    -webkit-transition-delay: 1.3s;
    transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
    -webkit-transition-delay: 1.3s;
    transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
    -webkit-transition-delay: 1.4s;
    transition-delay: 1.4s;
}

    .slide.animateIn.previous section span strong,
    .slide.animateIn.next section span strong {
        -webkit-transition-delay: 1.5s;
        transition-delay: 1.5s;
    }

.slide.animateOut h1 {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
}

.slide.animateOut p {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}

.slide.animateOut section span {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}

    .slide.animateOut section span strong {
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
    }





.slide-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
   
}

.slide-content-desc {
    position: absolute;
    bottom: 100px;
}

@media (max-width: 1300px) {
    .slide-content {
        padding-left: 60px;
    }
}



    @media (max-width: 991px) {

        .slide-content-desc {
            position: absolute;
            bottom: 100px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }

        .hero-slider {
            padding-top: 50px;
        }

        .slide-content {
            padding: 10px;
        }

        .hero-calltoaction {
            justify-content: center;
        }

    }



    .slide-content h1, .slide-description {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    @media (max-width: 600px) {

        .slide-content {
            padding: 5px;
            left: 50%;
            transform: translate(-50%, -0%);
        }

        .slider {
            height: 330px;
        }

        .slide-content h1 {
            font-size: 24px;
        }


        .slide-description {
            font-size: 1.1rem;
        }


        .slider a.previousButton {
            left: -10px;
        }

        .slider a.nextButton {
            right: -10px;
        }
    }


