
.footer {
    width: 100%;
    background: #dfdfdf;
    padding: 20px 0px 0px 0px;
}

.footer-content-left {
    width: 62%;
}

.footer-content-right {
    width: 38%;
}

.footer-content, .footer-content-left {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.company-info, .company-links {
    width: 50%;
}

.about-company, .about-company p, .about-company p span, .about-company h2 {
    background-color: transparent !important;
    font-size: 13px !important;
    font-weight: normal !important;
    margin-bottom: 0 !important;
    line-height: 24px!important;
}



    .company-info img {
        width: 160px;
        margin-bottom: 1rem;
    }

    .company-info p {
        font-size: 14px;
        color: #262626;
        line-height: 24px;
    }

.company-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 2rem;
}


.company-links--fast {
    min-width: 100px;
}


.socialmedia-content h5 {
    margin-bottom: 0;
}

.socialmedia-icons {
    display: flex;
}

.footer-link-title {
    color: #333;
    font-size: 15px;
    font-weight: bold;
}

.footer-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.footer-list li, .footer-content-right--list li{
  margin-bottom: 8px;
  display: flex;
 }



    .footer-list li > a {
        padding: 0;
        text-decoration: none;
        color: #262626;
        font-size: 14px;
        display: block;
        transition: all .25s cubic-bezier(.645,.045,.355,1);
    }

        .footer-list li > a:hover {
            transform: translateX(3px);
            color: #cb7100;
        }


.socialmedia-icons span {
    margin-right: 8px;
}

.footer-socialmedia-list {
    list-style-type: none;
    padding: 0;
    margin-top: 12px;
    display: flex;
}

    .footer-socialmedia-list li a {
        margin-right: 8px;
        transform: translateY(0);
        transition: all .25s cubic-bezier(.645,.045,.355,1);
        display: inline-block;
    }

        .footer-socialmedia-list li a:hover {
            transform: translateY(-3px);
        }



.facebook i,
.twitter i,
.instagram i,
.youtube i {
    font-size: 2rem;
}

.facebook i {
    color: #1b4195;
}

.twitter i {
    color: #31ade1;
}

.instagram i {
    color: #db2f4f;
}

.youtube i {
    color: #BD3A2C;
}

.footer-content-right--list {
    list-style-type: none;
    padding: 0;
    color: #777777;
    margin-bottom: 2rem;
}

    .footer-content-right--list li i {
        width: 36px;
        font-size: 1.5rem;
        color: #777777;
    }

 .footer-content-right--list li span {
        font-size: 14px;
        font-weight: 500;
        color: #262626;
 }

.footer-address{
    padding-left: 8px;
}

.footer-content-right--list li span .fa-envelope {
    margin-left: 8rem !important;
}

.footer-content-right {
    padding-top: 2rem;
}

.footer-link-title{
    margin-bottom: 16px;
}

.footer-link-title1 {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.footer-content-right p {
    font-size: 13px;
    font-weight: bold;
    color: #333;
}

footer {
    background: var(--main-color-orange);
    padding: 12px;

}

    footer p {
        font-size: 13px;
        color: #dce4f0;
        text-align: center;
    }


@media screen and (max-width: 991px) {
    .footer-content {
        padding: 0px 15px;
        flex-direction: column;
    }

    .footer-content-left, .footer-content-right {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .company-info, .company-links {
        width: 100%;
    }
}
