
.featuredCard {
    width: 280px;
    height: 400px;
    background: #ffffff;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #f2f2f2;
    position: relative;
    overflow: hidden;
    border-radius: 4;
    margin: 10px;
}


.featuredCard-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgba(0,0,0,0.5);
    opacity: 0;
    transition: opacity .2s ease-in-out;
    pointer-events: none;
}


.featuredCard-indirim {
    position: absolute;
    transform: rotate( 90deg);
    right: -34px;
    top: 34px;
    background: red;
    color: #fff;
    font-weight: bold;
    padding: 0px 15px;
    border-radius: 0px 0px 16px 0px;
}


 .featuredCard-overlay button {
        font-weight: bold;
    }

.featuredCard:hover .featuredCard-overlay {
    opacity: 1;
    pointer-events: initial;
}

.featuredCard-stats {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: #ffffff;
    width: 100%;
    padding: 15px;
    transition: bottom .3s ease-in-out;
    border-top: 1px solid #eeeeee;
}

.featuredCard-stats--title {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

.featuredCard-stats--price {
    color: #ff0000;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

.featuredCard:hover .featuredCard-stats {
    bottom: 0;
}



.featuredCard-overlay--img {
    width: 0;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}


    .featuredCard-overlay--img.show-img {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,0.8);
        z-index: 999;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

.overlay-img--box {
    max-width: 600px;
}

.show-img button {
    border: none;
    outline: none;
    color: #ffffff;
    position: inherit;
    top: 50px;
    right: 50px;
    background: transparent;
}

.btn-incele {
    margin-right: 5px;
}

.overlay-img--box img {
    width: 100%;
    object-fit: contain;
    height: auto;
}



@media screen and (max-width: 600px) {
    .featuredCard {
        width: 90%;
        margin: 10px auto;
    }
}



