.product-categories--wrapper {
    padding: 40px 20px;
   background: #ffffff;
}

.product-categories {
    display: flex;
    flex-wrap: wrap;
}


.product-categories {
    margin-bottom: 3rem;
}

.product-category--slide{
    padding-bottom: 4rem;
}