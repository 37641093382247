
.notfound-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #005182;
}

.notfound-container{
    max-width: 400px;
    text-align: center;
}

.notfound-container img{
    max-width: 100%;
    height: auto;
}

    .notfound-container p{
       color: #ffffff;
       margin: 20px;
    }


.notfound-container a{
    display:inline-block;
    text-align: center!important;
}