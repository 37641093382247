

.reference-container{
    display: flex; 
    flex-wrap:wrap;
    justify-content: center;
    padding: 30px 4px 40px 4px;
}

.referenceCard img {
    object-fit: contain;
    width: 100%;
    height: 180px;
    min-height: 180px;
    min-width:100%;
    background: #cccccc;
}
.referenceCard {
    position: relative;
    width: 290px;
    height: auto;
    border-radius: 12px;
    background: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    overflow: hidden;
    margin: 4px;
}

.referenceCard-footer{
   /* position: absolute;
    left: 0;
    bottom: 0;*/
    background: #ffffff;
    width: 100%;
    padding: 12px;
}

    .referenceCard-footer h1, .referenceCard-footer p {
        display: block;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.referenceCard-footer h1{
    font-size: 1.2rem;
    font-weight: bold;
}

    .referenceCard-footer p {
        font-size: 14px;
    
    }

@media screen and (max-width: 600px) {
    .reference-container {
      justify-content:center;
    }
}