.loader-wrapper
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity .3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: #fff;
    pointer-events: none;
}

    .loader-wrapper.on-loading {
        opacity: 1;
        pointer-events: initial;
    }

    .loader-content  svg {
        width: 100px;
        height: 100px;
        margin: 10px;
        display: inline-block;
    }

.cls-1, .cls-2 {
    fill: none;
    stroke-linecap: bevel;
    stroke-linejoin: round;
}

.cls-1 {
    stroke-width: 2px;
}

.cls-2 {
    fill: none;
    stroke: #777777;
    stroke-width: 4px;
}


.fa.fa-spinner {
    color: var(--main-color-orange);
}