.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #fbfbfb!important;
}

.product-category--slide .slick-slider .slick-next:before, 
.product-category--slide .slick-slider .slick-prev:before {
    font-size: 24px;
    line-height: 1;
    opacity: .75;
    color: #e22a2a;
}


.product-category--slide .slick-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #e22a2a!important;
}



@media screen and (max-width: 991px) {


    .product-category--slide .slick-slider .slick-next:before,
    .product-category--slide .slick-slider .slick-prev:before {
        opacity: 0;
    }
}



@media screen and (max-width: 600px) {

    .product-category--slide {
        overflow-x: hidden;
        min-height: 554px;
    }

}

