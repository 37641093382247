/*a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
    color: #243559;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}*/









.menu-header {
    width: 100%;
    margin: 0 auto;
    background: var(--main-color-orange);
    color: #333333;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
    box-shadow: 0px 1px 10px #999;
}

.header-top {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.header-top-contacts span {
    font-size: 14px;
    margin-right: 12px;
}

nav {
    background-color: #ffffff;
    position: relative;
}



    nav ul {
        padding: 0;
        margin: 0 auto;
        list-style: none;
        position: relative;
        text-align: end;
        max-width: 1200px;
    }

        nav ul li {
            display: inline-block;
            /*background-color: #fff;*/
            text-align: left;
        }

            nav ul li .main-link--item {
                display: block;
                padding: 0 10px;
                color: #333333;
                font-size: 14px;
                line-height: 50px;
                text-decoration: none;
                text-transform: uppercase;
                border-bottom: 2px solid transparent;
                font-weight: bold;
            }

            nav ul li .sub-link--item {
                display: block;
                padding: 0 10px;
                color: #FFF;
                font-size: 13px;
                line-height: 40px;
                text-decoration: none;
                font-weight: 600;
                border-bottom: 1px solid #ffffff2e;
            }


    nav a:hover {
        background-color: #e8e8e8;
        border-bottom: 2px solid #fff;
        color: #333333 !important;
    }

    nav a{
        color: #333333 !important;
    }

    /* Hide Dropdowns by Default */
    nav ul ul {
        /* 	display: none; */
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity .3s ease-in;
    }

    nav ul .first-tier-dropdown {
        top: 52px; /* the height of the main nav */
        background: #ffffff;
    }


    /* Display Dropdowns on Hover */
    nav ul li:hover > ul {
        display: inherit;
        opacity: 1;
        pointer-events: initial;
    }

    /* Fisrt Tier Dropdown */
    nav ul ul li {
        min-width: 170px;
        float: none;
        display: list-item;
        position: relative;
    }

    /* Second, Third and more Tiers	*/
    nav ul ul ul li {
        position: relative;
        top: 0px;
        left: 0px;
    }


/* Change this in order to change the Dropdown symbol */
nav li > a:after {
    content: ' ▾';
}

nav li > a:only-child:after {
    content: '';
}


.second-tier--list > a:after {
    position: absolute;
    right: 12px;
}



.first-tier--list, .second-tier--list {
    position: relative;
}


.first-tier--dropdowm, .second-tier--dropdowm {
    position: absolute;
}

.first-tier-dropdown {
    left: 0;
}

.second-tier--dropdown {
    left: 170px;
    top: 0px;
    background: #ffffff;
}




.menu-svg {
    width: 32px;
    height: 35px;
    fill: #333333;
    display: none;
    cursor: pointer;
}

.header-top--logo a {
    position: relative;
}

    .header-top--logo a img {
        width: auto;
        height: 64px;
        margin-bottom: -56px;
        padding-bottom: 1px;
        position: relative;
        z-index: 2;
        /* border-radius: 50%;*/
    }


.search-input {
    width: 0;
    padding-right: 15px;
    transition: width .2s ease-in-out;
    border: none;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    background: transparent;
    color: #ffffff;
}

.seach-box {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 0;
    right: -12px;
    color: #ffffff;
    width: 20px;
    height: 20px;
    z-index: 2;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

    .search-icon i {
        font-size: 16px;
    }

.search-input:focus {
    width: 150px;
    padding-right: 40px;
    cursor: default;
    outline: none;
    border-bottom: 1px solid #333333;
}

    .search-input:focus + .search-icon {
        pointer-events: initial;
    }

.header-top-contacts span a {
    color: #ffffff;
    text-decoration: none;
    transition: color .2s ease-in;
}


    .header-top-contacts span a:hover {
        cursor: pointer;
        text-decoration: underline;
    }


svg {
    pointer-events: auto;
}

path {
    pointer-events: auto;
}


@media screen and (max-width: 991px) {
    .mycontainer {
        padding: 0px 15px;
    }

    .header-top-contacts {
        display: none;
    }


    .header-top {
        padding: 8px;
        background: #ffffff;
    }

    #mainMenu {
        height: 0;
        overflow: hidden;
        transition: height .2s ease-in;
        max-width: 1200px;
        margin: 0 auto;
    }

    .header-top--logo a img {
        width: 88px;
        height: 38px;
        margin-bottom: -3px;
        padding-bottom: 1px;
        position: relative;
    }

    .menu-svg {
        display: block;
        fill: #777777;
    }

    #mainMenu.show {
        height: 100vh;
        overflow-y: auto;
    }

    nav ul {
        position: inherit;
        top: 0;
        left: 0;
        text-align: center;
    }


        nav ul li {
            display: block;
            position: relative;
        }

        nav ul ul {
            display: block;
            height: 0;
            position: relative;
        }

        nav ul li ul li {
            display: block;
            position: relative;
        }

        nav ul .first-tier-dropdown {
            top: 0;
            right: 0;
        }

    .second-tier--dropdown {
        top: 0px;
        left: 0;
        right: 27px;
    }


    nav ul li:hover > ul {
        height: auto;
        pointer-events: initial;
    }

    li > a:after {
        position: absolute;
        right: 5% !important;
    }


}