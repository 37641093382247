
.mybtn {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 0.6rem;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    text-align: center!important;
    line-height: 1;
    padding: .8rem 2rem;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

 .mybtn:hover, .mybtn:focus {
    color: #fff;
    outline: 0;
    text-decoration: none;
 }


.mybtn-default {
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    
}
    .mybtn-default:hover {
        box-shadow: 0 0 40px 40px var(--main-color-orange) inset;
        border: 2px solid #cb7100;
    }



@media (max-width: 600px) {
    .mybtn {
        padding: .7rem 1.2rem;
    }
}