.floatingicons-container {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.floatingicons-container ul {
  list-style-type: none;

}

 .floatingicons-container ul li a {
        width: 45px;
        height: 45px;
        border-radius: 50px;
        color: white;
        display: flex;
        justify-content:center;
        align-items: center;
        margin-top: 6px;
 }

        .floatingicons-container ul li a:hover {
            text-decoration: none;
        }

        .floatingicons-container ul li a:hover  i {
            transform: rotate(45deg);
        }


  .floatingicons-container ul li a i {
        font-size: 1.8rem;
        transition: all .3s ease-out;
  }

.ft-phone {
    background: #337ab7!important;
}
.ft-whatsapp {
    background: #4dc247;
}
.ft-telegram {
    background: #27A7E5;
}
.ft-instagram {
    background: #fd3a67;
}


@media screen and (max-width: 991px) {
    .floatingicons-container {
        bottom: 30px;
        left: -20px;
        z-index: 10;
        width: 76px;
    }

        .floatingicons-container ul li a {
            width: 35px;
            height: 35px;
        }

       .floatingicons-container ul li a i {
          font-size: 1.2rem;
       }

}


