
.Pagebreadcrumb-container{
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}


.breadcrumb-box h1 {
    text-align: center;
    margin-top: 50px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
}

.Pagebreadcrumb-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 53, 89, 0.75);
}

.breadcrumb-box {
    max-width: 360px;
    padding: 60px 0px 40px 0px;
    margin: 0 auto;
}


.breadcrumb-box nav{
   width: fit-content!important;
   margin: auto;
}

  .breadcrumb-box nav .breadcrumb-item {
     border-bottom: 2px solid transparent;
 }

@media screen and (max-width: 991px) {
    .breadcrumb-box h1 {
        font-size: 20px;
        margin-top: 34px;
    }

    .breadcrumb-box {
        padding: 40px 0px;
    }

    .breadcrumb {
        padding: 0.3rem 1rem;
    }

}