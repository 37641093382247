/*aboutus style*/

.aboutus {
    width: 100%;
    margin-bottom: 3rem;
    padding: 40px 30px;
}

.aboutus-content {
    display: flex;
}

.aboutus-content--text {
    width: 60%;
    color: #333333;
    padding: 142px 30px 30px 30px;
}



    .aboutus-content--text p {
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
    }

.aboutus-content--img {
    width: 40%;
    height: 320px;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-left: 100px;
    position: relative;
    z-index: 1;
}

    .aboutus-content--img::before {
        content: '';
        position: absolute;
        top: -84px;
        left: -100px;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: var(--main-color-orange);
        border-radius: 16px;
    }


    .aboutus-content--img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
        position: relative;
        -webkit-box-shadow: 1px 1px 16px 0 rgb(48 50 50 / 63%);
        box-shadow: 1px 1px 16px 0 rgb(48 50 50 / 63%);
    }




@media screen and (max-width: 991px) {

    .aboutus {
        margin-bottom: 2rem;
        padding: 40px 0px;
    }

    .aboutus-content {
        flex-direction: column;
        justify-content: center;
    }


    .aboutus-content--text, .aboutus-content--img {
        width: 95%;
        margin: auto;
    }


    .aboutus-content--text {
        padding: 30px 0px 0px 0px;
    }

      .aboutus-content--img::before {
            top: 0;
            left: 0;
      }

}
